// Initialize UIkit
UIkit.use(Icons);

// Document ready
document.addEventListener('DOMContentLoaded', function() {
    // Add any custom JavaScript here
    
    // Example: Initialize UIkit tooltips
    UIkit.tooltip('[data-uk-tooltip]');
    
    // Example: Initialize UIkit scrollspy
    UIkit.scrollspy('.uk-scrollspy');

    // Map Settings
    (function() {
        "use strict";
        
        // Check if map element exists
        const mapElement = document.getElementById('map');
        if (!mapElement) return;

        // Map initialization can go here
        // Example Google Maps initialization:
        const map = new google.maps.Map(mapElement, {
            center: { lat: 55.9310215, lng: -4.1417718 }, // PW Hall coordinates
            zoom: 15,
            styles: [
                {
                    featureType: "all",
                    elementType: "all",
                    stylers: [
                        { saturation: -100 }
                    ]
                }
            ]
        });

        // Add marker
        new google.maps.Marker({
            position: { lat: 55.9310215, lng: -4.1417718 },
            map: map,
            title: 'P.W. Hall Ltd'
        });
    })();
});